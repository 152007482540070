import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CustomerGroupProvider extends HttpRequest {
  findAll () {
    return this.get('/customer-contact')
  }

  findAllContact () {
    return this.get('/customer-contact/all/contact')
  }

  findAllGroup () {
    return this.get('/customer-contact/group-customer/all')
  }

  findByCustomerId (page = 1, limit = 10, id) {
    return this.get(`/customer-contact/${id}/profile?page=${page}&limit=${limit}`)
  }

  createGroup (payload) {
    return this.post('/customer-contact', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/customer-contact/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/customer-contact/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/customer-contact?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CustomerGroupProvider
