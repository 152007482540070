<template>
  <div>
    <ConfirmModal
      v-model="isDeleteModalActive"
      :items="deleteItem"
      title="Confirm to Delete Customer"
      @confirm="confirmDelete($event)" />
    <StatusModal
      v-model="isConfirmDeleteModalActive"
      status-title="Delete Customer Profile"
      status-description="Delete Customer Profile Succeed"
      status-color="#9F96F6"
      status-icon="Trash2Icon"
      @discard="onDiscard()" />
    <ConfirmModal
      v-model="isEditModalActive"
      :items="editItem"
      title="Confirm to Edit Customer Profile"
      @confirm="confirmEdit()" />
    <StatusModal
      v-model="isConfirmEditModalActive"
      status-title="Confirm to Edit Customer Profile"
      status-description="Edit Case Succeed"
      status-color="#28C76F"
      status-icon="CheckCircleIcon"
      @discard="onDiscard()" />
    <b-card
      @hidden="resetForm()">
      <template>
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="CustomerAddForm">
          <!-- Form -->
          <b-form
            ref="ResetForm"
            class="p-2"
            @submit.prevent="handleSubmit(updateCustomerInfo())"
            @reset.prevent="resetForm()">
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.customerId"
                  name="Customer Id"
                  label="Customer Id"
                  placeholder="Input Customer Id"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.name"
                  :rules="{ required: true }"
                  name="Customer Name"
                  label="Customer Name"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.customerTypeId"
                  :rules="{ required: true }"
                  name="Customer Type"
                  form-label="Customer Type"
                  placeholder="Choose Customer Type"
                  label-option="name"
                  return-value="id"
                  :options="customerType" />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.customerGroupId"
                  name="Customer Group"
                  form-label="Customer Group"
                  placeholder="Choose Customer Name"
                  label-option="name"
                  return-value="id"
                  :options="customerGroup" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.sapSoldToCode"
                  name="SAP Sold To Code"
                  label="SAP Sold To Code"
                  placeholder="Input Customer Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.sapShipToCode"
                  name="SAP Ship Code"
                  label="SAP Ship Code"
                  placeholder="Input SAP Ship Code"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode1"
                  name="Ref Code 1"
                  label="Ref Code 1"
                  placeholder="Input Ref Code 1"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode2"
                  name="Ref Code 2"
                  label="Ref Code 2"
                  placeholder="Input Ref Code 2"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.refCode3"
                  name="Ref Code 3"
                  label="Ref Code 3"
                  placeholder="Input Ref Code 3"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="customer.companyAddress"
                  name="Company Address"
                  label="Company Address"
                  placeholder="Input Company Address"
                  trim />
              </b-col>
              <b-col></b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companySubDistrict"
                  label="Company SubDistrict"
                  type="sub-district"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyDistrict"
                  label="Company District"
                  type="district"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyProvince"
                  label="Company Province"
                  type="province"
                  @select="onSelectAddress($event)" />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <AddressField
                  v-model="customer.companyZipCode"
                  label="Company ZipCode"
                  type="postcode"
                  @select="onSelectAddress($event)" />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyOfficePhone"
                  name="Company Office Phone"
                  label="Company Office Phone"
                  placeholder="Input Company Office Phone"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyMobilePhone"
                  name="Company Mobile Phone"
                  label="Company Mobile Phone"
                  placeholder="Input Company Mobile Phone"
                  trim />
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.ani"
                  name="Ani"
                  label="Ani"
                  placeholder="Input Ani"
                  trim
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <TextFieldInput
                  v-model="customer.companyEmail"
                  name="Company Email"
                  label="Company Email"
                  placeholder="Input Company Email"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="4">
              </b-col>
            </b-row>
            <b-row class="align-items-end">
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyCenterId"
                  name="Company Center"
                  form-label="Company Center"
                  placeholder="Choose Company Center"
                  :options="companyCenter"
                  label-option="name"
                  return-value="id"
                  @input="chooseCompanyCenter($event)"
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyGroupId"
                  name="Company Group"
                  form-label="Company Group"
                  placeholder="Choose Company Group"
                  :options="companyGroup"
                  label-option="name"
                  return-value="id"
                  disabled
                />
              </b-col>
              <b-col
                sm="12"
                md="4">
                <SelectField
                  v-model="customer.companyDepartmentId"
                  name="Group"
                  form-label="Company Dapartment"
                  placeholder="Choose Company Department"
                  :options="companyDepartment"
                  label-option="name"
                  return-value="id"
                  disabled
                />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark1"
                  :name="`Remark 1`"
                  :label="`Remark 1`" />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark2"
                  :name="`Remark 2`"
                  :label="`Remark 2`" />
              </b-col>
            </b-row>
            <b-row
            >
              <b-col
                sm="12"
                md="12">
                <TextAreaInput
                  v-model="customer.remark3"
                  :name="`Remark 3`"
                  :label="`Remark 3`" />
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col>
                <b-button
                  variant="Light"
                  @click="addRemark()">
                  <feather-icon
                    icon="PlusIcon" />
                  <span class="align-middle">Add Remark</span>
                </b-button>
              </b-col>
            </b-row> -->
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2 btn-submit"
                type="submit">
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="btn-submit"
                variant="outline-secondary"
                @click="onDiscard()">
                Discard
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
    <b-card title="Delete Account">
      <div class="demo-spacing-0">
        <b-alert
          variant="warning"
          show>
          <h4 class="alert-heading">
            You Are Delete Account Your Account
          </h4>
          <div class="alert-body">
            Once you delete your account, there is no going back. Please be certain.
          </div>
        </b-alert>
      </div>
      <b-checkbox
        v-model="statusCheckbox"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
        class="mt-2">
        I confirm my account deactivation
      </b-checkbox>
      <b-button
        :disabled="statusCheckbox !== 'accepted'"
        class="btn-submit mt-2"
        variant="danger"
        type="submit"
        @click="openDeleteModal(customer)">
        Deactivate Account
      </b-button>
    </b-card>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddressField from '@/components/Form/AddressField.vue'
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
import SelectField from '@/components/Form/SelectField.vue'
import TextAreaInput from '@/components/Form/TextAreaInput.vue'
import ConfirmModal from '@/components/Modal/ConfirmModal.vue'
import StatusModal from '@/components/Modal/StatusModal.vue'
import CustomerProvider from '@/resources/CustomerProvider'
import CustomerGroupProvider from '@/resources/CustomerGroupProvider'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'

const CustomerService = new CustomerProvider()
const CustomerGroupService = new CustomerGroupProvider()
const CustomerTypeService = new CustomerTypeProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()
const CompanyCenterService = new CompanyCenterProvider()

export default {
  components: {
    // CustomerAddContact,
    // CustomerDetailContact,
    AddressField,
    TextFieldInput,
    SelectField,
    TextAreaInput,
    ConfirmModal,
    StatusModal
    // TableTop
  },
  data () {
    return {
      isSidebarActive: false,
      isSidebarDetailActive: false,
      isEditModalActive: false,
      isDeleteModalActive: false,
      isConfirmEditModalActive: false,
      isConfirmDeleteModalActive: false,
      perPage: 10,
      customer: {
        customerId: '',
        name: '',
        ani: '',
        customerTypeId: '',
        customerGroupId: null,
        sapSoldToCode: '',
        sapShipToCode: '',
        refCode1: '',
        refCode2: '',
        refCode3: '',
        companyAddress: '',
        companySubDistrict: '',
        companyDistrict: '',
        companyProvince: '',
        companyZipCode: '',
        companyOfficePhone: '',
        companyMobilePhone: '',
        companyEmail: '',
        companyGroupId: null,
        companyDepartmentId: null,
        companyCenterId: null,
        remark1: '',
        remark2: '',
        remark3: '',
        queueAgent: 1
      },
      fields: [
        { key: 'id', label: 'Customer Id', sortable: true },
        { key: 'firstName', label: 'First Name' },
        { key: 'lastName', label: 'Last Name' },
        { key: 'contactPhoneNo', label: 'Contact PhoneNo' },
        { key: 'contactEmail', label: 'Contact Email' },
        { key: 'contactLine', label: 'Contact Line' },
        { key: 'detail', label: 'Detail' },
        { key: 'note1', label: 'Note1' },
        { key: 'note2', label: 'Note2' },
        { key: 'note3', label: 'Note3' },
        { key: 'note4', label: 'Note4' },
        { key: 'note5', label: 'Note5' }
      ],
      items: [
        {
          id: '#1234567',
          firstName: 'Lorem ipsum',
          lastName: 'Lorem ipsum',
          contactPhoneNo: 'Lorem ipsum',
          contactEmail: 'Lorem ipsum',
          contactLine: 'Lorem ipsum',
          detail: 'Lorem ipsum',
          note1: 'Lorem ipsum',
          note2: 'Lorem ipsum',
          note3: 'Lorem ipsum',
          note4: 'Lorem ipsum',
          note5: 'Lorem ipsum'
        }
      ],
      editItem: {},
      statusCheckbox: '',
      initialCustomerContact: {},
      selectedItem: {},
      customerGroup: [],
      customerType: [],
      companyGroup: [],
      companyDepartment: [],
      companyCenter: [],
      deleteItem: {}
    }
  },
  async created () {
    await this.getCustomerDetail()
    await this.getAllRef()
  },
  methods: {
    async getCustomerDetail () {
      const { data } = await CustomerService.findOne(this.$route.params.id)
      this.customer = data
    },
    async updateCustomerInfo () {
      try {
        const payload = { ...this.customer }
        payload.ani = this.customer.companyOfficePhone
        payload.queueAgent = 1
        delete payload.createdAt
        delete payload.createdBy
        delete payload.updatedAt
        delete payload.updatedBy
        delete payload.status
        delete payload.id
        await CustomerService.update(this.customer.id, payload)
        await this.getCustomerDetail()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Updated Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer Profile has been updated'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Customer Profile Updated Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `👋 Customer Profile has been failed ${error}`
          }
        })
      }
    },
    async DelCustomerDetail () { await CustomerService.delById(this.$route.params.id) },
    onSubmit () {
      // console.log('Submit')
    },
    async getAllRef () {
      try {
        const customerGroup = await CustomerGroupService.findAllGroup()
        const customerType = await CustomerTypeService.findAllType()
        const companyGroup = await CompanyGroupService.findAllGroup()
        const companyDepartment = await CompanyDepartmentService.findAllCompanyDepartment()
        const companyCenter = await CompanyCenterService.findAllCompanyCenter()
        this.customerType = customerType.data
        this.customerGroup = customerGroup.data
        this.companyGroup = companyGroup.data
        this.companyDepartment = companyDepartment.data
        this.companyCenter = companyCenter.data
      } catch (error) {
        console.error(error)
      }
    },
    async confirmDelete (val) {
      try {
        this.isConfirmDeleteModalActive = false
        await CustomerService.delById(val.ID)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 Customer has been deleted'
          }
        })
        this.$router.push('/customer')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Deleted Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: `😞 Customer has been Failed ${error}`
          }
        })
      }
    },
    activeSidebar () {
      this.isSidebarActive = true
    },
    onDiscard () {
      this.resetForm()
      this.$router.push('/customer')
    },
    resetForm () {
      this.$refs.ResetForm.reset()
    },
    openEditModal (val) {
      this.editItem = {
        'Customer ID :': val.customerId,
        'Customer Name :': val.customerName,
        'Customer Type :': val.customerType
      }
      this.isEditModalActive = true
    },
    confirmEdit () {
      this.isConfirmEditModalActive = true
    },
    openDeleteModal (val) {
      this.deleteItem = {
        'ID': val.id,
        'Customer ID :': val.customerId,
        'Customer Name :': val.name,
        'Customer Type :': this.findNameCustomerType()
      }
      this.isDeleteModalActive = true
    },
    findNameCustomerType () {
      const find = this.customerType.filter((item) => item.id === this.customer.customerTypeId)
      if (find && find.length > 0) {
        return find[0].name
      }
      return '0'
    },
    rowClick (val) {
      this.selectedItem = { ...val }
      this.initialCustomerContact = { ...val }
      this.isSidebarDetailActive = true
    },
    onSelectAddress (address) {
      this.customer.companySubDistrict = address?.subDistrict || ''
      this.customer.companyDistrict = address?.district || ''
      this.customer.companyProvince = address?.province || ''
      this.customer.companyZipCode = address?.postcode || ''
    },
    chooseCompanyCenter (event) {
      if (event && this.companyCenter && this.companyCenter.length > 0) {
        const { companyDepartmentId, companyGroupId } = this.companyCenter.filter((item) => item.id === event)[0]
        this.customer.companyGroupId = companyGroupId
        this.customer.companyDepartmentId = companyDepartmentId
        return
      }
      this.customer.companyGroupId = null
      this.customer.companyDepartmentId = null
    }
  }
}
</script>
