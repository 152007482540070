<template>
  <b-card>
    <b-tabs
      nav-wrapper-class="border-b"
      pills>
      <b-tab
        active
        title-link-class="rounded-pill"
        title="Customer Detail">
        <CustomerDetail />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="Customer Contact">
        <CustomerContact />
      </b-tab>
      <b-tab
        title-link-class="rounded-pill"
        title="Case Table">
        <CustomerTicketTable />
        <!-- <b-card-text>
        </b-card-text> -->
      </b-tab>
      <b-tab
        v-for="iframe in iframeTabs"
        :key="iframe.title"
        title-link-class="rounded-pill"
        :title="iframe.title">
        <iframe
          :title="iframe.title"
          :src="iframe.src"
          :width="iframe.width"
          :height="iframe.height"
          style="height: 100vh;"
        />
      </b-tab>
      <!-- <template>
      </template> -->
    </b-tabs>
  </b-card>
</template>

<script>
import CustomerContact from './CustomerContact.vue'
import CustomerTicketTable from './CustomerTicketTable.vue'
import CustomerDetail from '../CustomerDetail.vue'

export default {
  components: {
    CustomerDetail,
    CustomerTicketTable,
    CustomerContact
  },
  data () {
    return {
    }
  },
  computed: {
    iframeTabs () {
      return [
        {
          title: 'Last Invoice',
          src: 'https://csc-report.boonrawd.co.th/LastInvoice?custCode=1025686',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Last Invoice Agent',
          src: 'https://csc-report.boonrawd.co.th/LastInvoiceSml?custCode=25R9005&agentCode=4039000',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Cumulative Sale',
          src: 'https://csc-report.boonrawd.co.th/CumulativeSales?custCode=1025686',
          width: '100%',
          height: '100%'
        },
        {
          title: 'Cumulative Sale (sml)',
          src: ' https://csc-report.boonrawd.co.th/CumulativeSalesSml?custCode=21S0341&agentCode=4039000',
          width: '100%',
          height: '100%'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
