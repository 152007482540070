<template>
  <b-sidebar
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm()"
    @change="updateIsSidebarActive($event)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add Customer Contact
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide" />
      </div>
      <!-- BODY -->
      <ValidationObserver
        #default="{ handleSubmit }"
        ref="CustomerContactAddForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm()">
          <TextFieldInput
            v-model="customerContact.code"
            name="Code"
            label="Code "
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.firstName"
            name="First Name"
            label="First Name"
            :rules="{ required: true }"
            trim />
          <TextFieldInput
            v-model="customerContact.lastName"
            name="Last Name"
            label="Last Name"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.contactPhoneNo"
            name="Contact PhoneNo"
            label="Contact PhoneNo"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.contactPhoneNo2"
            name="Contact PhoneNo 2"
            label="Contact PhoneNo 2"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.contactEmail"
            name="Contact Email"
            label="Contact Email"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.contactLine"
            name="Contact Line"
            label="Contact Line"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.detail"
            name="Detail"
            label="Detail"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.note1"
            name="Note1"
            label="Note1"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.note2"
            name="Note2"
            label="Note2"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.note3"
            name="Note3"
            label="Note3"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.note4"
            name="Note4"
            label="Note4"
            :rules="{ required: false }"
            trim />
          <TextFieldInput
            v-model="customerContact.note5"
            name="Note5"
            label="Note5"
            :rules="{ required: false }"
            trim />
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 btn-submit"
              type="submit">
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="btn-submit"
              variant="outline-secondary"
              @click="hide">
              Cancel
            </b-button>
          </div>
        </b-form>
      </ValidationObserver>
    </template>
  </b-sidebar>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'

export default {
  components: { TextFieldInput },
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active'
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialCustomerContact: {
        code: '',
        firstName: '',
        lastName: '',
        contactPhoneNo: '',
        contactPhoneNo2: '',
        contactEmail: '',
        contactLine: '',
        detail: '',
        note1: '',
        note2: '',
        note3: '',
        note4: '',
        note5: ''
      },
      customerContact: {
        code: '',
        firstName: '',
        lastName: '',
        contactPhoneNo: '',
        contactPhoneNo2: '',
        contactEmail: '',
        contactLine: '',
        detail: '',
        note1: '',
        note2: '',
        note3: '',
        note4: '',
        note5: ''
      }
    }
  },
  methods: {
    updateIsSidebarActive (val) {
      this.$emit('update:is-sidebar-active', val)
    },
    onSubmit () {
      this.$emit('add-customer-contact', this.customerContact)
    },
    resetForm () {
      this.updateIsSidebarActive(false)
      this.customerContact = { ...this.initialCustomerContact }
      this.$refs.CustomerContactAddForm.reset()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-submit {
  min-width: 134px;
}
</style>
